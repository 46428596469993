import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", {
    class: "updateDate",
    style: _normalizeStyle({ color: _ctx.styleColor, 'font-size': _ctx.fontSize + 'px' })
  }, [
    _renderSlot(_ctx.$slots, "after", {}, undefined, true),
    _createElementVNode("label", null, [
      _createTextVNode(" 剩余 "),
      _createElementVNode("span", null, _toDisplayString(_ctx.day), 1),
      _createTextVNode(" 天 ")
    ]),
    _createElementVNode("label", null, [
      _createElementVNode("span", null, _toDisplayString(_ctx.hour), 1),
      _createTextVNode(" 时 ")
    ]),
    _createElementVNode("label", null, [
      _createElementVNode("span", null, _toDisplayString(_ctx.minute), 1),
      _createTextVNode(" 分 ")
    ]),
    _createElementVNode("label", null, [
      _createElementVNode("span", null, _toDisplayString(_ctx.second), 1),
      _createTextVNode(" 秒 ")
    ]),
    _renderSlot(_ctx.$slots, "before", {}, undefined, true)
  ], 4))
}