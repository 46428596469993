
import NoneContent from '@/components/noneContent.vue'
// import { getOrderInfo } from '@/api/order'
import orderApi from '@/http/apis/order'
import { defineComponent, reactive, toRefs, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Toast } from 'vant'
import {
  // OrderDetailListElType,
  // ProductListElType,
  // PackageListElType,
  InitStateType,
  LogisticsDetails,
  OrderDetailList,
  OrderLogisticsInformation,
} from '@/types/responseType'
import CountDown from '@/components/countDown.vue'
import { isWeixinBrowser } from '@/plugins/isWeixinBrowser'
import { OrderPaymentReq } from '@/types/requestType'
import { weixinPay } from '@/plugins/weixinPay'
import cookies from 'js-cookie'
export default defineComponent({
  name: 'OrderInfo',
  components: {
    NoneContent,
    CountDown,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const type = route.params.type as string //订单类型：shop | offline
    const orderId = route.params.orderNo as string //订单id：线上订单需要
    const servicePlace = route.query.servicePlace ? (route.query.servicePlace as string) : '' // 订单所属地：线下订单需要
    const orderNumber = route.params.orderNo ? (route.params.orderNo as string) : '' //订单号：线下订单需要
    const callbackurl = window.location.href // 物流详情返回url
    const state: InitStateType = reactive({
      isScroll: false,
      orderInfo: {} as any,
      orderDetailList: [],
      isToFixed: false,
      // packList: [] as any,
      // detailList: [] as any,
      nopackList: [], // 没有物流信息的明细
      packageList: [], // 有物流信息的明细
    })
    // 数量总价计算
    // const totalInfo = computed(() => {
    //   let total: number | string = 0,
    //     count = 0,
    //     typeCount = 0
    //   state.orderDetailList.forEach((el: OrderDetailListElType) => {
    //     if (el.type === 'nopackage') {
    //       el.totalPrice === '***'
    //         ? (total = '***')
    //         : total !== '***'
    //         ? ((total as number) += (el.totalPrice as number) * 1)
    //         : (total = '***')
    //       // total += el.totalPrice * 1
    //       count += el.quantity * 1
    //       typeCount += 1
    //     } else if (el.productList) {
    //       el.productList.forEach((product) => {
    //         product.totalPrice === '***'
    //           ? (total = '***')
    //           : total !== '***'
    //           ? ((total as number) += (product.totalPrice as number) * 1)
    //           : (total = '***')
    //         // total += product.totalPrice * 1
    //         count += product.quantity * 1
    //       })
    //       typeCount += el.productList.length
    //     }
    //   })
    //   !total && (total = '***')
    //   total !== '***' && (total = Number((total as number).toFixed(2)))
    //   return {
    //     typeCount,
    //     total,
    //     count,
    //   }
    // })
    // 计算数量
    const totalInfo = computed(() => {
      let count = 0
      state.orderInfo.orderDetailList.forEach((item: OrderDetailList) => {
        count += Number(item.quantity)
      })
      const typeCount = state.orderInfo.orderDetailList.length
      return {
        count,
        typeCount,
      }
    })

    // 判断是否显示查看物流
    const isLogisticsCheck = (value: string) => {
      if (value.includes('/')) {
        const arr = value.split('/').every((item: string) => item.includes('自提'))
        if (arr) {
          return false
        } else {
          return true
        }
      } else if (value.includes('自提') && !value.includes('/')) {
        return false
      } else {
        return true
      }
    }

    const getInfo = async () => {
      // const packageList: Array<OrderDetailListElType | PackageListElType> = []
      // let nopackList: Array<OrderDetailListElType | PackageListElType> = []
      const { data } =
        type === 'shop'
          ? await orderApi.getOrderDetail(orderId)
          : await orderApi.getOffOrderDetail({
              orderNumber,
              servicePlace,
            })

      state.orderInfo = data
      // const productSorts: number[] = []
      // 订单全部列表
      // nopackList = data.orderDetailList.map((el: OrderDetailListElType) => {
      //   switch (el.orderDetailStatus) {
      //     // case '已确认':
      //     // case '备货中':
      //     // case '待收货':
      //     // case '已完成':
      //     case 'stockUp':
      //     case 'pendingDelivery':
      //     case 'finish':
      //       el.statusShow = 'date'
      //       break
      //     default:
      //       el.statusShow = 'days'
      //       break
      //   }
      //   el.type = 'nopackage'
      //   return el
      // })
      // 订单包裹列表
      // data.packages &&
      //   data.packages.forEach((el: PackageListElType) => {
      //     el.productList = []
      //     el.packageProductList.forEach((product) => {
      //       // productSorts.push(product.productSort)
      //       if (el.productList) {
      //         el.productList.push(
      //           data.orderDetailList.find((detail: OrderDetailListElType) => {
      //             // return detail.orderSort == product.productSort
      //           })
      //         )
      //       }
      //     })
      //     el.type = 'package'
      //     packageList.push(el)
      //   })
      // productSorts.forEach((sort) => {
      //   nopackList.forEach((detail, index) => {
      //     ;(detail as OrderDetailListElType).orderSort == sort && nopackList.splice(index, 1)
      //   })
      // })
      // state.orderDetailList = nopackList.concat(packageList)
      state.nopackList = []
      const sorts = [] as any
      if (data.orderLogisticsInformation) {
        data.orderLogisticsInformation.forEach((item: OrderLogisticsInformation) => {
          data.orderDetailList.forEach((item4: OrderDetailList) => {
            item.logisticsDetails.map((item5: LogisticsDetails) => {
              if (item4.sortId === item5.orderSort) {
                sorts.push(item5.orderSort)
                item5.imageUrl = item4.imageUrl
                item5.orderDetailStatusColor = item4.orderDetailStatusColor
                item5.delivery = item4.delivery
                item5.estimatedShippingDate = item4.estimatedShippingDate
                item5.customerModel = item4.customerModel
                item5.estimatedShippingDate = item4.estimatedShippingDate
                item5.taxDiscountPrice = item4.taxDiscountPrice
                item5.totalPrice = item4.totalPrice
                item5.statusName =
                  item4.orderDetailStatus === 'unpaid'
                    ? '待支付'
                    : item4.orderDetailStatus === 'paid'
                    ? '已支付'
                    : item4.orderDetailStatus === 'untreated'
                    ? '待确认'
                    : item4.orderDetailStatus === 'pendingDelivery'
                    ? '待发货'
                    : item4.orderDetailStatus === 'canceling'
                    ? '取消中'
                    : item4.orderDetailStatus === 'stockUp'
                    ? '备货中'
                    : item4.orderDetailStatus === 'takeDelivered'
                    ? '待收货'
                    : item4.orderDetailStatus === 'finish'
                    ? '已完成'
                    : item4.orderDetailStatus === 'cancel'
                    ? '已取消'
                    : item4.orderDetailStatus === 'closed'
                    ? '已终止'
                    : item4.orderDetailStatus === 'confirm'
                    ? '已确认'
                    : ''
              }
              return item5
            })
          })
        })
        // 找出订单明细中不存在物流包裹的明细项
        data.orderDetailList.forEach((item2: OrderDetailList) => {
          !sorts.includes(item2.sortId) && state.nopackList.push(item2)
        })
        state.packageList = data.orderLogisticsInformation
      } else {
        state.nopackList = data.orderDetailList
      }
      state.nopackList.map((item: OrderDetailList) => {
        if (
          item.orderDetailStatus === 'pendingDelivery ' ||
          item.orderDetailStatus === 'stockUp' ||
          item.orderDetailStatus === 'takeDelivered' ||
          item.orderDetailStatus === 'finish'
        ) {
          item.statusShow = 'date'
        } else {
          item.statusShow = 'days'
        }
        item.statusName =
          item.orderDetailStatus === 'unpaid'
            ? '待支付'
            : item.orderDetailStatus === 'paid'
            ? '已支付'
            : item.orderDetailStatus === 'untreated'
            ? '待确认'
            : item.orderDetailStatus === 'pendingDelivery'
            ? '待发货'
            : item.orderDetailStatus === 'canceling'
            ? '取消中'
            : item.orderDetailStatus === 'stockUp'
            ? '备货中'
            : item.orderDetailStatus === 'takeDelivered'
            ? '待收货'
            : item.orderDetailStatus === 'finish'
            ? '已完成'
            : item.orderDetailStatus === 'cancel'
            ? '已取消'
            : item.orderDetailStatus === 'closed'
            ? '已终止'
            : item.orderDetailStatus === 'confirm'
            ? '已确认'
            : ''
        return item
      })
    }
    // 复制
    const copy = () => {
      const input = document.createElement('input')
      input.value = state.orderInfo.orderNumber
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      Toast.success('复制成功')
    }
    // 查看物流
    const checkLogistics = (value: any) => {
      // console.log(value, 'vvv')
      router.push(
        `/orderlogistic?logisticCode=${value.expressNumber}&logisticCompany=${
          value.logisticsCompany
        }&orderNumber=${state.orderInfo.orderNumber}&orderType=${type}&serviceProducts=${
          value.serviceProducts ? value.serviceProducts : ''
        }&origin=${value.origin ? value.origin : ''}&contactNumber=${value.contactNumber || ''}`
      )
    }
    // 立即支付
    const immediatelyPay = () => {
      const DATA: OrderPaymentReq = {
        orderNumber: state.orderInfo.orderNumber,
        jumpUrl: `${window.location.origin}/order`,
        isForbidBack: 0,
        isWxPay: isWeixinBrowser() ? 'true' : 'false',
        channel: 'wechat',
      }
      if (isWeixinBrowser()) {
        DATA.openid = cookies.get('openid')
      }
      orderApi
        .orderPay(DATA)
        .then((res) => {
          if (isWeixinBrowser()) {
            weixinPay(res.data)
              .then((res) => {
                console.log(res)
              })
              .catch((err) => {
                console.log(err)
                router.push(`/orderInfo/shop/${orderId}`)
              })
          } else {
            window.location.href = res.data.pageUrl
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    // 初始获取数据
    onMounted(() => {
      getInfo()
    })
    return {
      type,
      orderId,
      callbackurl,
      ...toRefs(state),
      totalInfo,
      copy,
      immediatelyPay,
      isLogisticsCheck,
      checkLogistics,
    }
  },
})
