
import { defineComponent, onMounted, onUnmounted, reactive, toRefs } from 'vue'
export interface Tt {
  day: string
  hour: string
  minute: string
  second: string
  seconds: number
}
export default defineComponent({
  props: ['createdDate', 'styleColor', 'fontSize'],
  setup(props, { emit }) {
    let timer: any = null
    const state: Tt = reactive({
      day: '00',
      hour: '00',
      minute: '00',
      second: '00',
      seconds: 1209600,
    })

    const timeInit = () => {
      const day = Number(state.seconds / (24 * 60 * 60)).toFixed(0)
      state.day = Number(day) < 10 ? `0${day}` : day.toString()
      const hour = Number((state.seconds / (60 * 60)) % 24).toFixed(0)
      state.hour = Number(hour) < 10 ? `0${hour}` : hour.toString()
      const minute = Number((state.seconds / 60) % 60).toFixed(0)
      state.minute = Number(minute) < 10 ? `0${minute}` : minute.toString()
      const second = Number(state.seconds % 60).toFixed(0)
      state.second = Number(second) < 10 ? `0${second}` : second.toString()
    }

    /**
     * 描述 倒计时
     * @date
     * @returns {any}
     */
    const countTime = () => {
      // 获取当前时间
      const now = new Date().getTime()
      // 创建时间
      const create = new Date(props.createdDate.replace(/-/g, '/')).getTime()
      // 时间差
      const leftTime = (now - create) / 1000
      state.seconds = state.seconds - leftTime > 0 ? state.seconds - leftTime : 0
      timer = setInterval(() => {
        if (state.seconds > 0) {
          state.seconds -= 1
          timeInit()
        } else {
          clearInterval(timer)
        }
      }, 1000)
    }

    onMounted(() => {
      countTime()
    })

    // 清除定时器
    onUnmounted(() => {
      timer = null
    })
    return {
      timer,
      ...toRefs(state),
      timeInit,
      countTime,
    }
  },
})
