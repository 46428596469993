<template>
  <div class="none-content">
    <div v-if="info.type === 'image'" class="img-content">
      <van-image :src="imgNone" fit="contain" alt="暂无图片" />
    </div>
    <svg v-if="info.type === 'content'" class="iconMb mb-blank" aria-hidden="true">
      <use xlink:href="#mb-blank" />
    </svg>
    <div v-if="info.description" class="description">
      {{ info.description }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'NoneContent',
  props: ['info'],
  data() {
    return {
      imgNone: require('@/assets/img/img-none.png'),
    }
  },
}
</script>
<style lang="less" scoped>
.none-content {
  width: 100%;
  height: 100%;
  text-align: center;
  .mb-zanwutupian1 {
    color: #999;
  }
  .img-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .mb-blank {
    width: 100%;
    width: 100%;
    padding-top: 20px;
  }
  .description {
    padding-bottom: 10px;
    line-height: 25px;
    color: #999;
    font-size: 12px;
  }
}
</style>
